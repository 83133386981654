import React, { Component } from 'react';
import LikeButton from './Tile/LikeButton';
import DislikeButton from './Tile/DislikeButton';
import AddButton from './Tile/AddButton';
import LikeBar from './Tile/LikeBar';
import { settings } from '../helpers/settings.js';
import { Link } from 'react-router-dom';
import { filterWhere } from '../helpers/filterWhere.js';

// import { LazyLoadImage } from 'react-lazy-load-image-component';

class Tile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data
    };
  }
  //deprecated
  onLikeClick(event) {
    alert("Liked");
  }

  //deprecated
  onDislikeClick(event) {
    alert("Dislike");
  }

  //deprecated
  onAddClick(event) {
    alert("Added");
  }

  componentWillReceiveProps(props) {
    this.setState({});
  }

  onError(e) {
    // e.target.src = "/Thumbnails/fallback.png"; e.target.onerror = ""
  }

  // <LazyLoadImage className="tile__img" src={"https://testv2.microw-courses.com/uploads/course/" + this.state.data.ThumbnailURL} onError={this.onError} afterLoad={console.log} />

  isANewTile(date) {
    if (date) {
      return Math.ceil((new Date() - new Date(date)) / 86400000) < 28
    }
    else {
      return false
    }
  }
  render() {
    const { data, accessedData } = this.props;

    let accessed = filterWhere({ CourseID: this.state.data.id }, accessedData)[0]

    return (
      <div real="true" className={"tile" + (this.props.curr ? " currTile" : "")} ref={(t) => { this.t = t }}>
        <div>
          <span className="text-justify" data-toggle="tooltip" title={settings.Catalogue.hoverDescriptionEnabled && this.state.data.Description ? this.state.data.Description : ''}>
            <div className="tile__media">
              <img className="tile__img" src={"/Thumbnails/" + this.state.data.ThumbnailURL} />
            </div>
            <div className="tile__details">
              <div className="tile_info">
                <div className="tile__title"><h3> {this.state.data.Title} </h3></div>
                <div className="tile__desc"><p>{this.state.data.Description}</p></div>
              </div>
              <div className="tile_bar">
                <div className="bottom">
                  <LikeButton likes={this.state.data.Likes} cID={this.state.data.id} enabled={settings.Catalogue.likesEnabled} />
                  <DislikeButton dislikes={this.state.data.Dislikes} cID={this.state.data.id} enabled={settings.Catalogue.dislikesEnabled} />
                  <AddButton cID={this.state.data.id} enabled={settings.Catalogue.addEnabled} />
                </div>
              </div>
              <LikeBar likes={this.state.data.Likes} dislikes={this.state.data.Dislikes} cID={this.state.data.id} enabled={settings.Catalogue.likebarEnabled} />
              <div className="playbtn">
                <Link to={"watch/" + this.props.subscriptionID + "/" + this.state.data.id}>
                  <i className="fa fa-play-circle"></i>
                </Link>
              </div>
            </div>
          </span>
        </div>
        {!accessed && this.isANewTile(this.props.data.contentAddedOn) &&
          <div className="tile-badge-container">
            <div>
            <span className="tile-badge new-badge">NEW VIDEO</span>
            </div>
          </div>
        }
        {accessed && 
          <div className="tile-badge-container">
            <div>
            <span className="tile-badge new-badge">ACCESSED</span>
            </div>
          </div>
        }

      </div>
    )
  }
}

export default Tile
