import React, { Component } from 'react';
import './Slider.css';
import Tile from './Tile';
import hexToRgb from '../helpers/hexToRgb.js';
import { connect } from 'react-redux';
import BlankScreen from '../components/Loading'

/*
  This component is complicated, it contains all of the exact and specific
  logic for the slider, scrolling, tile hovering, tile locking, next/previously
  clicks etc.

  This also crosses over with jquery functionality as that is what it was
  originally designed for. Ordinarilly this would be bad practice, however
  this was my only option unless I wanted to convert the entire logic to react.

  to see the rest of the logic, see the javascript in the index.html file.
 */


class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Tiles: props.data,
      accessedData: props.accessedData,
      curr: 0
    };
    this.nextClick = this.nextClick.bind(this);
    this.prevClick = this.prevClick.bind(this);

  }
  componentDidMount() {
    //Resize on first load
    this.children = this.row.childNodes[1].childNodes

    this.realChildren = []
    for (var i = 0; i < this.children.length; i++) {
      if (this.children[i].getAttribute("real") === "true") {
        this.realChildren.push(this.children[i])
      }
    }

    this.setTileOnResize = (event) => {
      this.sliderup(event, this)
    }

    window.addEventListener('sliderResize', this.setTileOnResize);
    window.sliderResize();

    this.setTileOnResize();
    var moveEvent = new CustomEvent('sliderResize', {
      bubbles: true
    })

    window.dispatchEvent(moveEvent);
    this.nextClick();
  }

  componentWillUnmount() {
    window.removeEventListener('sliderResize', this.setTileOnResize);
  }

  //Scrolls to next video in the slider
  nextClick() {
    var c = this.state.curr;
    if (c < this.realChildren.length) {
      c += 1
      this.setState({ curr: c });
    }
    var child = this.children[c];
    this.row.scrollLeft = child.offsetLeft - this.next.clientWidth - parseInt(child.style.marginRight);
  }
  //Scrolls to the previous video in the slider
  prevClick() {
    var c = this.state.curr;
    if (c > 1) {
      c -= 1;
      this.setState({ curr: c });
    }
    var child = this.children[c];
    this.row.scrollLeft = child.offsetLeft - this.next.clientWidth - parseInt(child.style.marginRight);
  }

  //Simulated click event for mobile touch compatibility
  sliderdrag(e, i) {
    e.pageX = e.touches[0].pageX;
    i.lastX = e.pageX;
    this.slidermove(e, i);
  }

  //Simulated click event for mobile touch compatibility
  slidertap(e, i) {
    e.pageX = e.touches[0].pageX;
    this.sliderdown(e, i);
  }

  //Simulated click event for mobile touch compatibility
  slideruntap(e, i) {
    e.pageX = i.lastX;
    this.sliderup(e, i);
  }

  //Scrolls the slider to match the cursor's position
  slidermove(e, i) {
    if (i.curDown) {
      i.leftMove = (i.curXPos + i.scrLeft - e.pageX)
      i.row.scrollLeft = i.leftMove;
    }
  }

  //Only allows scroll when mouse is clicked
  sliderdown(e, i) {
    e.preventDefault();
    i.curDown = true;
    i.scrLeft = this.row.scrollLeft;
    i.curXPos = e.pageX;
  }

  //Stop scrolling when slider is released
  //Then lock position to the first nearest tile in the slider
  sliderup(event, i) {
    //i  = this, the slider
    //e = the event (not used, but is triggered as an event)
    
    //mouse not down
    i.curDown = false;

    var dist = i.children[0].clientWidth + parseInt(i.children[1].style.marginRight)

    //current tile?
    var c = i.state.curr;

    for (var x = 0; x < i.children.length; x++) {
      var s = i.children[x];
      if (i.row.scrollLeft > (s.offsetLeft - i.next.clientWidth - parseInt(s.style.marginRight) - dist / 2) &&
        i.row.scrollLeft < (s.offsetLeft - i.next.clientWidth - parseInt(s.style.marginRight) + dist / 2 + 1)) {
        c = x;
        break;
      }
    }
    i.setState({ curr: c });
    i.row.scrollLeft = i.children[c].offsetLeft - i.next.clientWidth - parseInt(i.children[c].style.marginRight);
  }

  componentWillReceiveProps(props) {
    this.setState({});
  }

  isRowInnerSmall(rowInner) {
    return false
    // if(rowInner && rowInner.clientWidth && document.window.clientWidth){
    //   return document.window.clientWidth > rowInner.clientWidth;
    // }
    // else{
    //   return true;
    // }
  }
  
  isANewTile(date){
    return Math.ceil((new Date() - new Date(date)) / 86400000) < 28
  }

  render() {
    const { brandings } = this.props;
    if (brandings.loading) {
      return (<BlankScreen />);
    }    
    var rgb = brandings.branding && brandings.branding.BackgroundColour && hexToRgb(brandings.branding.BackgroundColour)

    if (!rgb) { rgb = {} };

    var opacity = 1;
    if (brandings.branding && brandings.branding.Opacity != null && brandings.branding.Opacity != undefined) {
      opacity = brandings.branding.Opacity
    }
    if (this.state.curr === 0 && this.children) {
      this.nextClick();
    }

    let sliderOpacity = opacity
    if (brandings.branding && brandings.branding.SliderButtonOpacity != null && brandings.branding.SliderButtonOpacity != undefined) {
      sliderOpacity = brandings.branding.SliderButtonOpacity
    }

    let sliderRGB = brandings &&  brandings.branding.SliderButtonColour && hexToRgb(brandings.branding.SliderButtonColour)
    if(!sliderRGB){
      sliderRGB = rgb;
    }

    return (
      
      <div className="video-slider-outer">
        <h2 style={{ color: brandings.branding && brandings.branding.TextColour }}> {this.props.title}         
        </h2>
        <div className="video-slider">
          <div className="row" ref={(row) => { this.row = row }} curr={this.state.curr} onMouseMove={(e) => this.slidermove(e, this)} onTouchMove={(e => this.sliderdrag(e, this))}>
            <div className="prev" ref={(prev) => { this.prev = prev }} onClick={this.prevClick} style={{ background: "rgba(" + sliderRGB.r + ", " + sliderRGB.g + ", " + sliderRGB.b + ", " + sliderOpacity + ")" }}><i className="fa fa-angle-left"></i></div>
            <div ref={(rowInner) => this.rowInner = rowInner} className="row__inner" onMouseDown={(e) => this.sliderdown(e, this)} onMouseUp={(e) => this.sliderup(e, this)} onMouseLeave={(e) => this.sliderup(e, this)} onTouchStart={(e) => this.slidertap(e, this)} onTouchEnd={(e) => this.slideruntap(e, this)}>
              <div className="tile firstTile">
                <div className="tile__media" />
                <div className="tile__details" />
              </div>
              {this.state.Tiles.children.map((t, ind) => {
                return <Tile accessedData={this.state.accessedData} key={ind} data={t} curr={(this.state.curr - 1) == ind} subscriptionID={this.state.Tiles.subscriptionID ? this.state.Tiles.subscriptionID : t.subscriptionID } expired={this.state.Tiles.expired} />
              })}
              <div className="tile">
                <div className="tile__media" />
                <div className="tile__details" />
              </div>
              <div className="tile">
                <div className="tile__media" />
                <div className="tile__details" />
              </div>
              <div className="tile">
                <div className="tile__media" />
                <div className="tile__details" />
              </div>
              <div className="tile">
                <div className="tile__media" />
                <div className="tile__details" />
              </div>
              <div className="tile">
                <div className="tile__media" />
                <div className="tile__details" />
              </div>
            </div>
            <div className="next" ref={(next) => { this.next = next }} onClick={this.nextClick} style={{ background: "rgba(" + sliderRGB.r + ", " + sliderRGB.g + ", " + sliderRGB.b + ", " + sliderOpacity + ")" }}><i className="fa fa-angle-right"></i></div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { brandings } = state;

  return {
    brandings
  };
}
const connectedSlider = connect(mapStateToProps)(Slider);
export default connectedSlider
