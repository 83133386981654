import React, { Component } from 'react';
import { connect } from 'react-redux';
import Slider from './Slider';
import Jumbotron from './Jumbotron';
import { userextensionActions } from '../actions';
import { brandingMessage } from '../helpers/brandingMessage.js';
import { Navigate } from 'react-router-dom';
import { concatCatalogue } from '../helpers/concatCatalogue';
import { recurseCatalogue } from '../helpers/recurseCatalogue';
import { contenthistoryService, userService } from '../services';
import recommend from 'collaborative-filter'
import { concatFrom } from '../helpers/concatFrom';

class Catalogue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lazyLoaded: 3,
      maxLoad: -1,
      hashedID: null,
      anonymisedHistory: null,
      recommendations: null,
      courses: null,
      recommendationsSlider: null,
      redirectSomewhere: null
    }
    delete props.userextensions.items //Delete persisted data
    this.addSlider()
    this.dataHandled = false;
  }

  createIndexedMatrix(users, courses) {
    const matrix = users.map((user) =>
      courses.map((CourseID) => {
        const watched = user.courses.find((id) => id === CourseID)
        if (watched) {
          return 1
        } else {
          return 0
        }
      }
      )
    )
    return matrix
  }

  createRecommendationSlider() {
    const recommendationsCap = 10
    const { recommendations, courses } = this.state;

    let CappedRecommendations = [];
    if (recommendations != null && recommendations.length > 0) {
      CappedRecommendations = recommendations.slice(0, recommendationsCap);
    }
    //console.log("recommendations", recommendations);

    if (recommendations && courses) {
      this.state.recommendationsSlider = {
        ActiveFlag: true,
        Name: "Recommendations!",
        children: courses.filter(course => CappedRecommendations.includes(course.id)),
        expired: false,
        id: null, // ToDo
      }
    }
  }

  processRecommendations() {
    Promise.all([
      userService.getHashedID().then((res) => {
        //console.log("hashedID", res);
        this.state.hashedID = res[0].HashedID
      }),
      contenthistoryService.getAnonymisedHistory().then((res) => {
        //console.log("anon history", res);
        this.state.anonymisedHistory = res
      })
    ]).then(() => {
      const { anonymisedHistory, hashedID } = this.state;
      let courses = [];
      let users = [];


      anonymisedHistory.map((historyItem) => {
        if (!courses.includes(historyItem.CourseID)) {
          courses.push(historyItem.CourseID)
        }
      })

      anonymisedHistory.map((historyItem) => {
        let user = users.find(u => u.UserID === historyItem.UserID)
        if (!user) {
          user = { UserID: historyItem.UserID, courses: [] }
          users.push(user)
        }
        user.courses.push(historyItem.CourseID)
      })


      const matrix = this.createIndexedMatrix(users, courses)
      const userIndex = users.findIndex(user => user.UserID === hashedID)

      //console.log("Alex - content recommendation\n", matrix, userIndex, users, hashedID)
      const results = recommend.cFilter(matrix, userIndex);


      const recommendedCourseIDS = results.map(index => courses[index])
      this.setState({ ...this.state, recommendations: recommendedCourseIDS })
      this.createRecommendationSlider();

    })
  }

  componentDidMount() {
    if (!this.props.authentication.user && localStorage.getItem('user')) {
      window.location.reload();
    }
    this.props.dispatch(userextensionActions.getAllFiltered(this.props.authentication.user.userId)).then((user) => {
      if (user && user.redirect) {
        this.setState({ redirectSomewhere: user.redirect });
      }
    });
    //When document loads, get data for current user extension
    var moveEvent = new CustomEvent('sliderResize', {
      bubbles: true
    })
    window.dispatchEvent(moveEvent);
    this.processRecommendations();

  }

  componentWillReceiveProps(props) {
    this.setState({});
  }

  addSlider() {
    setTimeout(function () { //Start the timer
      if (this.state.maxLoad === -1) {
        this.addSlider()
      } else {
        this.setState({ lazyLoaded: this.state.lazyLoaded + 1 }) //After 1 second, set render to true
        if (this.state.lazyLoaded < this.state.maxLoad) {
          this.addSlider()
        }
      }
    }.bind(this), 500)
  }

  shouldUsePhoneNumber(phoneNumber, contactNumber) {
    return !(!phoneNumber || phoneNumber === "03333 449918" || phoneNumber === "") &&
      (!contactNumber || contactNumber === "03333 449918" || contactNumber === "")
  }

  noContentMessage(message, href = null, contactNumber = null, style = {}) {
    const phoneNumber = this.props.brandings.branding.PhoneNo;
    let numberToShow = contactNumber;
    let hrefToShow = href;

    if (this.shouldUsePhoneNumber(phoneNumber, contactNumber)) {
      numberToShow = phoneNumber;
      hrefToShow = `tel:${phoneNumber}`;
    }

    var lines = [];
    message = message.replace("%partnername%", this.props.brandings.branding.ResellerName)
    message.split("\n").map((section, i) => {
      if (section.includes("%contactnumber%")) {
        var line = section.split("%contactnumber%")
        lines.push(<p className="headlines text-sh" style={style} key={i}> {line[0]} <a href={hrefToShow}>{numberToShow}</a> {line[1]} </p>);
      } else {
        lines.push(<p className="headlines text-sh" style={style} key={i}> {section} </p>);
      }
    })
    return lines;
  }

  render() {
    const { userextensions, brandings } = this.props;

    const { recommendationsSlider, redirectSomewhere } = this.state;
    var items;
    var hasSubscriptions;
    var username = "";

    if (redirectSomewhere) {
      return (<Navigate to={redirectSomewhere} />);
    }

    // If response from userextensions
    if (userextensions.items && userextensions.items[0].activeSubscriptions && !this.dataHandled) {

      hasSubscriptions = userextensions.items[0].activeSubscriptions[0] !== undefined; //Do they have any subscriptions?
      username = userextensions.items[0].FirstName ? userextensions.items[0].FirstName : userextensions.items[0].Name.split(" ")[0]; // Temporary catch for firstname not existing whilst v2test transition.
      items = recurseCatalogue(concatCatalogue(userextensions.items))

      let accessedData = concatFrom("watched",
          userextensions.items[0]
      )
      //console.log("accessedData", accessedData)
      this.state.accessedData = accessedData;

      let courses = []
      items.children.map((slider) => {
        courses = [...courses, ...slider.children.map((course) => { return { ...course, subscriptionID: slider.subscriptionID } })];
      })
      this.state.courses = courses;
      this.createRecommendationSlider();
      this.state.maxLoad = items.children.length;
      this.dataHandled = { hasSubscriptions, username, items };
    } else {
      if (this.dataHandled) {
        hasSubscriptions = this.dataHandled.hasSubscriptions
        items = this.dataHandled.items
        username = this.dataHandled.username
      }
    }
    //If recieved response
    if (userextensions.items) {
      //Show catalogue if user has at least one subscription
      if (hasSubscriptions) {
        return (
          <div>
            <Jumbotron
              title={brandingMessage(brandings, "welcome_header", "Welcome, %name%").replace("%name%", username)}
              subtitle={brandingMessage(brandings, "welcome_subtitle", "Your Microw content is below")} />
            <div className="Catalogue">
              {userextensionActions.loading && <em>Loading catalogueitems...</em>}
              {userextensionActions.error && <span className="text-danger">ERROR: {userextensionActions.error}</span>}

              {recommendationsSlider && brandings.branding.showSmartRecommendations && recommendationsSlider.children.length > 0 && <Slider key="recommendationSlider" title={recommendationsSlider.Name} data={recommendationsSlider} />}

              {items &&
                items.children.map((s, index) => {
                  if (s.children.length > 0 && index < this.state.lazyLoaded) {
                    return <Slider key={s.catalogueItemID} title={s.Name} data={s} accessedData={this.state.accessedData} />
                  }
                })}

            </div>
          </div>
        )
      } else {
        //otherwise, tell them to buy one
        return (
          <div>
            <Jumbotron title={"Welcome, " + username} subtitle="" />
            <div className="container">
              {
                brandings.branding &&
                brandings.branding.messages &&
                brandings.branding.messages.catalogue_no_content_message &&
                this.noContentMessage(
                  brandingMessage(brandings, "catalogue_no_content_message", "Whoops… it looks like your portal is empty right now and you don’t have access to any of our videos.\nBut don’t worry – a quick call to our onboarding team on %contactnumber% will enable us to help you out.\nThe %resellername% Onboarding Team"),
                  brandingMessage(brandings, "contact_href", "tel:+443333449918"),
                  brandingMessage(brandings, "contact_number", "03333 449918"),
                  { color: brandings.branding && brandings.branding.PrimaryColour }
                )
              }
            </div>
            <br /><br /><br />
          </div>
        )


      }
    } else {
      //Otherwise content is still loading
      return (
        <div>
          <Jumbotron title={"Welcome, " + username} subtitle="" />
          <div className="container">
            Loading your content
          </div>
          <br /><br /><br />
        </div>
      )
    }

  }
}
function mapStateToProps(state) {
  const { userextensions, authentication, brandings } = state;

  return {
    userextensions, authentication, brandings
  };
}
const connectedCatalogue = connect(mapStateToProps)(Catalogue);
export default connectedCatalogue
